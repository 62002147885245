import Box from "@mui/material/Box";
import Button, { type ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";

export type ImageInputOutputExpectedProps = {
  leftButton: ButtonProps;
  rightButton?: ButtonProps;
};

const ImageInputOutputExpected: React.FC<ImageInputOutputExpectedProps> = props => {
  const [open, setOpen] = React.useState(false);
  const leftButtonProps: ButtonProps = {
    ...props.leftButton,
    onClick: event => {
      setOpen(prev => !prev);
      props.leftButton.onClick?.(event);
    },
  };
  return (
    <Box>
      {open && <Typography>Hello World</Typography>}
      <Button {...leftButtonProps} />
      {props.rightButton && <Button {...props.rightButton} />}
    </Box>
  );
};

ImageInputOutputExpected.displayName = "ImageInputOutputExpected";

export default ImageInputOutputExpected;
